import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoginModal from "./loginmodal";

class RawD3 extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0, showLogin: false };
    this.sectionRef = React.createRef();
    const baseUrl = process.env.PUBLIC_URL + "/images/brands/clearpea/sequence/liquid";
    this.images = Array.from({ length: 500 }, (_, i) => `${baseUrl}${(i + 1).toString().padStart(3, "0")}.webp`);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const section = this.sectionRef.current;
    const { top, bottom } = section.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    if (top <= viewportHeight && bottom >= 0) {
      const sectionStart = top - viewportHeight;
      const sectionEnd = bottom;
      const visibleRange = sectionEnd - sectionStart;
      const scrollProgress = viewportHeight - top;
      const fraction = Math.max(0, Math.min(scrollProgress / visibleRange, 1));
      const index = Math.floor(fraction * (this.images.length - 1));
      if (index !== this.state.currentIndex) {
        this.setState({ currentIndex: index });
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <LoginModal show={this.state.showLogin} close={() => this.setState({ showLogin: false })} />
        <section
          style={{
            position: "relative",
            background: "white",
            height: "1000px",
            marginTop: "-80px",
          }}
          className="makeHeaderTransparent"
        >
          <video
            src={`${process.env.PUBLIC_URL}/images/brands/rawd3/1.mp4`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
            autoPlay
            muted
            playsInline
            loop
          >
            {t("title228")}
          </video>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.)",
              pointerEvents: "none",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="text-center max-w-70">
              <img
                src={`${process.env.PUBLIC_URL}/images/brands/rawd3_light.png`}
                style={{ maxHeight: 100, width: "auto" }}
                alt="Rawd3 Logo"
              />
              <h4 className="alt-font-3 font-weight-600 text-white mt-4">Sunshine. Sourced from Pine Extract</h4>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-20">
            <div className="row">
              <div className="col-12">
                <div className="cont">
                  <h2 className="alt-font font-weight-600 text-black line-height-60px">
                    <span className="color-font" dangerouslySetInnerHTML={{ __html: t("text49") }} />
                  </h2>
                  <h5 className="mt-5 text-extra-large-2 text-black font-weight-500 margin-5px-bottom d-block">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/brands/rawd3_dark.png`}
                      style={{ height: 24, width: "auto", marginLeft: 2 }}
                      alt="Rawd3 Dark Logo"
                    />{" "}
                    {t("text50")}
                    <br /> <br /> {t("text66")}
                    <br />
                    <u onClick={() => this.setState({ showLogin: true })} className="cursor-pointer">
                      {t("text67")}
                    </u>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white pt-5 makeHeaderTransparent">
          <div ref={this.sectionRef} style={{ height: "60vh", position: "relative" }}>
            <video
              src={`${process.env.PUBLIC_URL}/images/brands/rawd3/3.mp4`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              autoPlay
              muted
              playsInline
              loop
            >
              {t("title228")}
            </video>
            <div className="centered-container">
              <div className="row">
                <div className="col-12 col-lg-4 d-none d-lg-block">
                  <b className="text-white mt-5 d-block">
                    <i className="fa fa-check text-white mr-2 mb-5" style={{ fontSize: "2.2rem" }}></i>{" "}
                    <span style={{ fontSize: "2.2rem" }}>{t("text51")}</span>
                    <br />
                    <i className="fa fa-check text-white mr-2 mb-5" style={{ fontSize: "2.2rem" }}></i>{" "}
                    <span style={{ fontSize: "2.2rem" }}>{t("text52")}</span>
                    <br />
                    <i className="fa fa-check text-white mr-2 mb-5" style={{ fontSize: "2.2rem" }}></i>{" "}
                    <span style={{ fontSize: "2.2rem" }}>{t("text53")}</span>
                    <br />
                    <i className="fa fa-check text-white mr-2 mb-5" style={{ fontSize: "2.2rem" }}></i>{" "}
                    <span style={{ fontSize: "2.2rem" }}>{t("text54")}</span>
                  </b>
                </div>
                <div className="col-12 col-lg-8">
                  <h2 className="alt-font font-weight-600 text-white w-95 line-height-60px">
                    {t("text55")}{" "}
                    <img
                      src={`${process.env.PUBLIC_URL}/images/brands/rawd3_light.png`}
                      style={{ height: 54, width: "auto", marginLeft: 2 }}
                      alt="Rawd3 Light Logo"
                    />
                  </h2>
                  <h5 className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-75">
                    {t("text56")}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="alt-font font-weight-600 text-black w-95 line-height-60px">
                  <span className="color-font">{t("text57")}</span>
                </h2>
                <h5 className="my-5 text-extra-large-2 text-black font-weight-500 margin-5px-bottom d-block w-100">
                  {t("text58")}
                </h5>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 col-lg-6">
                <div
                  className="card-hover mt-2"
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "100%",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      background: `url(${process.env.PUBLIC_URL}/images/brands/rawd3/4.jpg) no-repeat center/cover`,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                  <div
                    className="cardd-title"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontWeight: "bold",
                      textShadow: "1px 1px 10px rgba(0,0,0,0.1)",
                      zIndex: 2,
                    }}
                  >
                    {t("text59")}
                  </div>
                  <div
                    className="description-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0,0,0,0.8)",
                      color: "white",
                      opacity: 0,
                      transition: "opacity 0.3s",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px",
                      zIndex: 3,
                    }}
                  >
                    {t("text60")}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div
                  className="card-hover mt-2"
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "100%",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      background: `url(${process.env.PUBLIC_URL}/images/brands/rawd3/5.jpg) no-repeat center/cover`,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                  <div
                    className="cardd-title"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontWeight: "bold",
                      textShadow: "1px 1px 10px rgba(0,0,0,0.1)",
                      zIndex: 2,
                    }}
                  >
                    {t("text61")}
                  </div>
                  <div
                    className="description-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0,0,0,0.8)",
                      color: "white",
                      opacity: 0,
                      transition: "opacity 0.3s",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px",
                      zIndex: 3,
                    }}
                  >
                    {t("text62")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light mb-10 py-0">
          <div className="container my-30">
            <div className="row">
              <div className="col-12 col-lg-8 my-20">
                <div className="cont">
                  <h2 className="alt-font font-weight-600 text-black w-95 line-height-60px">
                    <span className="color-font">
                      {t("text63")}{" "}
                      <img
                        src={`${process.env.PUBLIC_URL}/images/brands/rawd3_dark.png`}
                        style={{ height: 54, width: "auto", marginLeft: 2 }}
                        alt="RawD3 Dark Logo"
                      />
                    </span>
                  </h2>
                  <h5 className="mt-5 text-extra-large-2 text-black font-weight-500 margin-5px-bottom d-block w-95">
                    {t("text64")}
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    paddingTop: "120%",
                    overflow: "hidden",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/brands/rawd3/2.jpg`}
                    alt={t("text65")}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white" style={{ backgroundColor: "rgb(2,2,2)" }}>
          <div className="container">
            <div className="overlap-section card specification-card py-2 bg-light border-none">
              <div className="border-radius-6px padding-4-half-rem-tb padding-4-rem-lr sm-no-padding-lr wow animate__pulse">
                <div className="row align-items-center justify-content-center">
                  <div className="col-10 col-xl-8 text-xl-start lg-margin-40px-bottom text-center text-xl-left">
                    <span className="text-extra-medium alt-font text-black">{t("title343")}</span>
                    <h6 className="alt-font font-weight-600 text-black letter-spacing-minus-1-half m-0">
                      {t("title344")}
                      <img
                        src={`${process.env.PUBLIC_URL}/images/brands/rawd3_dark.png`}
                        style={{ height: 24, width: "auto", marginLeft: 5 }}
                        alt="ClearPEA Light Logo"
                      />{" "}
                      {t("title345")}
                    </h6>
                  </div>
                  <div className="col-22 col-lg-3 text-center">
                    <a
                      className="btn btn-medium btn-neon-orange btn-fancy btn-round-edge section-link"
                      href={`mailto:info@rawbids.com?subject=Sample Request - RawD3&body=${encodeURIComponent(
                        `Sehr geehrte Damen und Herren,

bitte schicken Sie mir ein Sample von RawD3 an folgende Adresse:

[HIER ADRESSE ANGEBEN]

Mit freundlichen Grüßen,

[IHR NAME]`
                      )}`}
                    >
                      {t("title346")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-10 col-md-10 wow animate__fadeInLeft" data-wow-delay="0.5s">
                <h4 className="alt-font-3 font-weight-600 text-black w-100 mb-4">Spezifikation</h4>
                <div className="d-flex flex-column bg-medium-gray px-0 mx-0">
                  <div
                    className="d-flex align-items-center justify-content-between my-3 w-100"
                    style={{ marginLeft: 25, lineHeight: 2 }}
                  >
                    <div className="d-flex flex-column">
                      <span className="h3 text-black font-weight-700 mb-0 pb-0">
                        <img src="/images/brands/rawd3_dark.png" style={{ height: 24, width: "auto", marginLeft: 2 }} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card bg-light border-none" style={{ position: "relative", overflow: "hidden" }}>
                  <div className="card-blur m-5">
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Basic Information</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">CAS Number</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">fG34k9kz9g</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Origin Country</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Zylandiatonia</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Article Number</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">78654bb12a</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Organic</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Blorbgliata</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Category</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Xyzzylongterm</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">HS Code</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">1234 5678 90 1234</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Composition</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Plumbusonation</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Extraction Solvent</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Gloop, Zorp, Blip, Snorfla</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Color</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Glorbiano</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Density</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">987 - 654 kg/m3</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Physical &amp; Chemical Parameters</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">Appearance</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Frobnicatelyso</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Ash</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">≤ 12345%</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Limits</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Qwopylonger</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Loss on Drying</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">≤ 905%</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Odor</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Blurghishness</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Particle Size</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">42% through 999 mesh</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Purity</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">&gt;57% above</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Specific Rotation</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Wibbletonia</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">pH</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">6.78 approximately</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Labels &amp; Standards</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">CITES</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Gliptonous</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">ECHA</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">Friptoniously</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Halal</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">12345678</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Hazard Material</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">98765432</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Kosher</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">6243121</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Novel Food</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">678902134</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Packaging</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">246809753</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Vegan</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">135798246</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Vegetarian</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">0246861357</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Grade</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">Cosmetic Grade</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Zilcheryestian</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Feed Grade</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Nadalyonsious</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Food Grade</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Blorbsncity</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Industrial Grade</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Snorflietyus</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Medicine Grade</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Glooplyishious</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Pharmaceutical Grade</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Zorptilblion</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">USP Grade</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Plumbuslyerous</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Contaminants</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">Aflatoxins</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">&lt; 10 ppb</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Benzopyrene</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">≤ 10 ppb</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Max. Allowed ETO</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">≤ 0.1 ppm</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">PAH 4</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">≤ 50 ppb</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Active Substances</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">Protein</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">85%</div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">Peptides</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">80%</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Allergens</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">
                          <div className="badge badge-dark p-1 px-2">Pea</div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Additional Information</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">Storage Conditions</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Cool, Dry Place</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Shelf Life</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">24 Months</div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-black font-weight-500 font-size-lg">Temperature</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">15-25°C</div>
                        <div className="col-3 text-black font-weight-500 font-size-lg">Humidity</div>
                        <div className="col-3 text-gray-1 font-weight-500 font-size-lg">Below 60%</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-black h4 mb-4">Specification</div>
                    </div>
                    <div className="container" style={{ lineHeight: "1.9" }}>
                      <div className="row">
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 75,
                          }}
                        >
                          <img src="/images/pdf.svg" alt="PDF Icon" style={{ width: 24, marginBottom: 5 }} />
                          <div style={{ fontSize: "0.9rem", color: "black" }}>Cola</div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 75,
                          }}
                        >
                          <img src="/images/pdf.svg" alt="PDF Icon" style={{ width: 24, marginBottom: 5 }} />
                          <div style={{ fontSize: "0.9rem", color: "black" }}>Green Apple</div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 75,
                          }}
                        >
                          <img src="/images/pdf.svg" alt="PDF Icon" style={{ width: 24, marginBottom: 5 }} />
                          <div style={{ fontSize: "0.9rem", color: "black" }}>Cherry</div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 75,
                          }}
                        >
                          <img src="/images/pdf.svg" alt="PDF Icon" style={{ width: 24, marginBottom: 5 }} />
                          <div style={{ fontSize: "0.9rem", color: "black" }}>Orange</div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 75,
                          }}
                        >
                          <img src="/images/pdf.svg" alt="PDF Icon" style={{ width: 24, marginBottom: 5 }} />
                          <div style={{ fontSize: "0.9rem", color: "black" }}>Ice Tea Peach</div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 75,
                          }}
                        >
                          <img src="/images/pdf.svg" alt="PDF Icon" style={{ width: 24, marginBottom: 5 }} />
                          <div style={{ fontSize: "0.9rem", color: "black" }}>Tropical</div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-medium btn-fancy btn-neon-orange btn-round-edge card-button">
                    Register now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </>
    );
  }
}

export default withTranslation("general")(RawD3);
