import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Header extends Component {
  state = {
    showLanguageSelection: false,
    showBrandsSelection: false,
    screenWidth: null,
    isTransparent: false,
  };

  showLanguageSelection = () => {
    this.setState({ showLanguageSelection: true });
  };

  hideLanguageSelection = () => {
    setTimeout(() => {
      this.setState({ showLanguageSelection: false });
    }, 250);
  };

  showBrandsSelection = () => {
    this.setState({ showBrandsSelection: true });
  };

  hideBrandsSelection = () => {
    setTimeout(() => {
      this.setState({ showBrandsSelection: false });
    }, 250);
  };

  setLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  getLocalStorageLocales = () => {
    return localStorage.getItem("i18nextLng").split(/-|_/)[0];
  };

  getBrowserLocales = (options = {}) => {
    const { languageCodeOnly = false } = options;
    const browserLocales = navigator.languages || [navigator.language];
    if (!browserLocales) return "de";
    return languageCodeOnly ? browserLocales[0].split(/-|_/)[0] : browserLocales[0].trim();
  };

  componentDidMount() {
    console.log("Language is:", localStorage.getItem("i18nextLng"));
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
    this.handleResize();
    this.handleScroll();

    const query = this.getBrowserLocales({ languageCodeOnly: true });
    const storage = localStorage.getItem("i18nextLng");
    if (!storage && (query === "de" || query === "en")) {
      localStorage.setItem("i18nextLng", query);
      window.location.reload();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  handleScroll = () => {
    requestAnimationFrame(() => {
      const headerEl = document.querySelector("header");
      if (!headerEl) return;
      const headerBottom = 0; // Manuell auf 80 gesetzt
      let isTransparent = false;
      const transparentSections = document.querySelectorAll("section.makeHeaderTransparent");
      transparentSections.forEach((section) => {
        const { top: sectionTop, bottom: sectionBottom } = section.getBoundingClientRect();
        if (headerBottom >= sectionTop && headerBottom < sectionBottom) {
          isTransparent = true;
        }
      });

      this.setState({ isTransparent });
    });
  };

  render() {
    const { t, show } = this.props;
    const { showLanguageSelection, showBrandsSelection, isTransparent } = this.state;

    const textStyle = { color: isTransparent ? "#fff" : "", transition: "color 0.3s ease" };
    const navStyle = isTransparent
      ? {
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(10px)",
        }
      : { backgroundColor: "#fff" };

    return (
      <React.Fragment>
        <header>
          <nav className="navbar navbar-expand-lg navbar-light fixed-top header-reverse-scroll" style={navStyle}>
            <div className="container container-xxl nav-header-container">
              <div className="col-6 col-lg-2 mr-auto pl-lg-0">
                <a className="navbar-brand" href="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${isTransparent ? "logo-light" : "logo-dark"}.png`}
                    alt="Logo"
                    className="default-logo"
                  />
                </a>
              </div>
              <div className="col-auto menu-order px-lg-0">
                <button
                  className="navbar-toggler float-right"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                  <ul className="navbar-nav alt-font">
                    <li className="nav-item">
                      <a href="/" className="nav-link futura2" style={textStyle}>
                        {t("title1")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/quality" className="nav-link futura2" style={textStyle}>
                        {t("title2")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/technology" className="nav-link futura2" style={textStyle}>
                        {t("title3")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/mission" className="nav-link futura2" style={textStyle}>
                        {t("title4")}
                      </a>
                    </li>
                    <li
                      className="nav-item dropdown simple-dropdown"
                      onMouseEnter={this.showBrandsSelection}
                      onMouseLeave={this.hideBrandsSelection}
                    >
                      <span className="nav-link futura2" style={textStyle}>
                        {t("title224")}
                      </span>
                      <ul className={`dropdown-menu ${showBrandsSelection ? "d-block" : "d-none"}`} role="menu">
                        <li className="nav-item">
                          <a href="/brands/clearpea" className="futura2" style={textStyle}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/brands/clearpea_dark.png`}
                              style={{ height: 20, width: "auto" }}
                              alt="ClearPea"
                              className="cursor-pointer"
                            />
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/brands/rawcaps" className="futura2" style={textStyle}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_dark.png`}
                              style={{ height: 20, width: "auto" }}
                              alt="RawCaps"
                              className="cursor-pointer"
                            />
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/brands/rawd3" className="futura2" style={textStyle}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/brands/rawd3_dark.png`}
                              style={{ height: 20, width: "auto" }}
                              alt="RawD3"
                              className="cursor-pointer"
                            />
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/investor" className="nav-link futura2" style={textStyle}>
                        {t("title5")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <span onClick={show} className="nav-link futura2" style={textStyle}>
                        {t("title16")}
                      </span>
                    </li>
                    <li
                      className="nav-item dropdown simple-dropdown"
                      onMouseEnter={this.showLanguageSelection}
                      onMouseLeave={this.hideLanguageSelection}
                    >
                      <a href="#!" className="nav-link disabled" onClick={() => this.setLanguage("en")}>
                        <img
                          src={`/images/countries_128/${this.getLocalStorageLocales() || "en"}.png`}
                          alt="Flag"
                          className="fab"
                          style={{ width: 16 }}
                        />
                      </a>
                      <i className="fa fa-angle-down dropdown-toggle" aria-hidden="true" style={textStyle} />
                      <ul className={`dropdown-menu ${showLanguageSelection ? "d-block" : "d-none"}`} role="menu">
                        <li className="dropdown">
                          <a href="#!" onClick={() => this.setLanguage("en")} className="text-black">
                            <img
                              src="/images/countries_128/en.png"
                              alt="English"
                              className="fab mr-2"
                              style={{ width: 16 }}
                            />
                            {t("title7")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="#!" onClick={() => this.setLanguage("de")} className="text-black">
                            <img
                              src="/images/countries_128/de.png"
                              alt="Deutsch"
                              className="fab mr-2"
                              style={{ width: 16 }}
                            />
                            {t("title6")}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <a
                    href="https://app.rawbids.com/login"
                    className="header-button btn btn-very-small btn-neon-orange btn-round-edge font-weight-600 mb-10 mb-lg-0 ml-2"
                    style={textStyle}
                  >
                    {t("title83")}
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Header);
