import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./i18n";

import HomePage from "./pages/homePage";
/* import AboutUs from "./pages/aboutUs"; */
import Certifications from "./pages/certifications";
import Imprint from "./pages/imprint";
import Portfolio from "./pages/portfolio";
import Privacy from "./pages/privacy";
import Production from "./pages/production";
import Team from "./pages/team";
import Error404 from "./pages/error404";
import Tracking from "./components/tracking/Tracking";
import ConfiguratorWrapper from "./components/configurator/ConfiguratorWrapper";
import LoginModal from "./pages/loginmodal";
import CalendarModal from "./pages/calendarmodal";
import Header from "./layout/header";
import Footer from "./layout/footer";
import Hotjar from "@hotjar/browser";

import "react-toastify/dist/ReactToastify.css";

// icons
import "./assets/plugins/keenthemes-icons/font/ki.css";
import "./assets/plugins/flaticon/flaticon.css";
import "./assets/plugins/flaticon2/flaticon.css";
import "./assets/plugins/line-awesome/css/line-awesome.min.css";

import "./index.scss";

import "../src/assets/css/font-icons.min.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/swiper.min.css";
import "../src/assets/css/justified-gallery.min.css";
import "../src/assets/css/animate.min.css";
import "../src/assets/css/magnific-popup.min.css";
import "../src/assets/css/custom-scrollbar.min.css";
import "../src/assets/css/animated-headlines.min.css";
import "../src/assets/css/jquery-ui.min.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/style.css";
import "../src/assets/css/custom.css";
import ClearPea from "./pages/clearPea";
import RawCaps from "./pages/rawCaps";
import RawD3 from "./pages/rawD3";

class App extends Component {
  componentDidMount() {
    // load js scripts AFTER rendering
    let script = document.createElement("script");
    script.src = "scripts.js";
    script.id = "scripts";
    document.body.appendChild(script);

    const siteId = 3931036;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  }

  download(param) {
    console.log("param: ", param);

    switch (param) {
      case "aeb":
        param = "GB-AEB-0001";
        break;
      case "avlb":
        param = "GB-AVLB-0001";
        break;
      case "agb":
        param = "GB-AGB-0001";
        break;
      default:
        break;
    }

    fetch("pdf/" + param + ".pdf", {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", param + `.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        console.log(link);
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }
  closeLogin = () => {
    this.setState({ showLogin: false });
  };
  showLogin = () => {
    this.setState({ showLogin: true });
  };
  closeCalendar = () => {
    this.setState({ showCalendar: false });
  };
  showCalendar = () => {
    this.setState({ showCalendar: true });
  };
  state = { showLogin: false, showCalendar: false };

  render() {
    return (
      <React.Suspense fallback={"Loading..."}>
        <div className="App">
          <Header show={this.showLogin} />
          <LoginModal show={this.state.showLogin} close={this.closeLogin} />
          <CalendarModal show={this.state.showCalendar} close={this.closeCalendar} />
          <Router>
            <ToastContainer limit={2} />
            <Switch>
              <Route path="/mission">
                <Certifications show={this.showLogin} />
              </Route>

              <Route path="/imprint">
                <Imprint />
              </Route>
              <Route path="/quality">
                <Portfolio show={this.showLogin} showCalendar={this.showCalendar} />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/investor">
                <Production />
              </Route>
              <Route path="/technology">
                <Team />
              </Route>
              <Route path="/brands/clearpea">
                <ClearPea download={this.download} />
              </Route>
              <Route path="/brands/rawcaps">
                <RawCaps download={this.download} />
              </Route>
              <Route path="/brands/rawd3">
                <RawD3 download={this.download} />
              </Route>

              <Route path="/agb">{() => this.download("agb")}</Route>
              <Route path="/avb">{() => this.download("avlb")}</Route>
              <Route path="/aeb">{() => this.download("aeb")}</Route>

              <Route path="/">
                <HomePage showLogin={this.showLogin} showCalendar={this.showCalendar} />
              </Route>
              <Route path="*">
                <Error404 />
              </Route>
            </Switch>
          </Router>
          <Footer />
        </div>
      </React.Suspense>
    );
  }
}

export default App;
